import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import MarketplaceForm from "../components/marketplaceForm/marketplaceForm"
import Card from "../components/card/card";
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"
import FAQs from "../components/faqs/faqs";

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "";
const contentMaxWidth = "780px";

const dataFilters = [
    {
        id: "general",
        label: "General"
    },
    {
        id: "onboarding",
        label: "Onboarding"
    }
];

const dataFAQs = [
    {
        title: "Who is EVVA for?",
        description: "<p>We’re currently open to any Mexican residents who are a director of a limited company, and non-limited businesses that are structured as a partnership (Sociedades SAS, SAPI, SA de CV o SC) or sole trader.</p>",
        categories: ["general"]
    },
    {
        title: "Is EVVA a bank?",
        description: "<p>No, EVVA is a digital platform through which connects you with relevant financial services for your business. All financial services offered on the platform are carried out by one or more financial institutions, which are regulated and supervised by the CNBV and CONDUSEF.</p>",
        categories: ["general"]
    },
    {
        title: "What is a EVVA Business account?",
        description: "<p>EVVA business account is a low risk investment account with immediate liquidity so you can withdraw your money at any time, the account is issued by Kuspit Casa de Bolsa and the fund is managed by Scotia Global Asset Management, a ScotiaBank company. For detailed information about this fund please read the following <a href='https://scotiabankfiles.azureedge.net/scotia-bank-mexico/spanish/pdf/personas/fondos-de-inversion/prospectos/Prospecto_SCOTIAG.pdf?t=1592919143996' target='_blank' rel='noopener noreferrer'>document</a></p>",
        categories: ["general"]
    },
    {
        title:"Why is the service called EVVA?",
        description: "<p>EVVA comes from our manifesto:<br /><strong>Entrepreneurship:</strong> our daily inspiration. We are focused on the entrepreneur’s financial needs so they can focus on what really matters: their business.</p><p><strong>Veracity:</strong> being transparent and direct with our customers. We are totally against hidden fees and complex pricings.</p><p><strong>Velocity:</strong> everything changes at a lightspeed pace. Being agile and adaptable is key to succeed. We craft agile financial experiences with the ultimate goal of making small businesses grow to the next level.</p><p><strong>Accessible:</strong> being inclusive. Our services must be open to all entrepreneurs that want to create an impact and contribute to its country’s economy.</p>",
        categories: ["general"]
    },
    {
        title: "What other services are available in EVVA?",
        description: "<p>With our products and allies we are developing a full suite of services for your business. Visit our <a href='/en/marketplace' target='_self' rel='noopener noreferrer'>marketplace page for more information.</a></p>",
        categories: ["general"]
    },
    {
        title: "What’s the best way to contact EVVA?",
        description: "<p>If you’re a customer Through the web app chat feature, where you can contact us during office hours.</p><p>You can also email us at <a href='mailto:susbcripciones@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>susbcripciones@evvafinanzas.com</a> or ayuda@evvafinanzas.com to sort out issues or share ideas on how we can help you more.</p><p>If you’re curious drop us a line at <a href='mailto:info@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>info@evvafinanzas.com</a> We’re always happy to answer questions.</p>",
        categories: ["general"]
    },
    {
        title: "How much does EVVA cost?",
        description: "<p>You pay a simple flat monthly fee, depending on how much you use EVVA and the stage of the company. Plans cost $0 for newly established companies and $175 MXN for companies already invoicing. For detailed information visit our pricing page.</p>",
        categories: ["general"]
    },
    {
        title: "What do I need for opening a business account business?",
        description: "<p>SMEs established as limited companies:</p><ul><li>CURP ID of legal representative</li><li>Front photo of the INE card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop,  ensure that it is completely clear</li><li>Photo of back of the voter card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, ensure that it is completely clear</li><li>Articles of association in pdf format</li><li>Act of change of powers, this applies in case your company has suffered any change of legal representative</li><li>Proof of fiscal domicile in pdf, recent not more than 3 months, can be a water, electricity or phone bill</li><li>Tax certificate of your business, which you can generate on the SAT portal</li><li>FIEL (digital signature of your business) have your .KEY .CER files and password at hand</li></ul><p>If you are a sole proprietorship with business activit</p><ul><li>CURP ID</li><li>Front photo of the  INE card  of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, it only ensures that it is completely clear</li><li>Photo of back of the INE card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, just ensure that it is completely clear</li><li>Proof of fiscal domicile in pdf, recent not more than 3 months, can be a water, electricity or phone bill</li></ul>",
        categories: ["onboarding"]
    },
    {
        title: "What information do I need from my business?",
        description: "<p>Business address, Tax ID RFC and phone number of your company</p>",
        categories: ["onboarding"]
    }
];

const Marketplace = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const marketplaceFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <MarketplaceForm lang={appState.lang} title="Thank you for your interest!" subtitle="We are about to launch 🚀! Register and a get preferential access as soon as possible." extraFields="services" amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpMarketplaceCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const contactUsFormHandler = () => {
        window.location.href="mailto:partners@evvafinanzas.com";
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    const categoryFAQsHandler = (id) => {
        const { categoryFAQs } = appState;
        const categoryId = categoryFAQs.find(item => item === id);

        if (categoryId) {
            const index = categoryFAQs.indexOf(categoryId);

            if (index > -1 && categoryFAQs.length > 1) {
                categoryFAQs.splice(index, 1);
            }

        } else {
            //categoryFAQs.push(id)
            categoryFAQs[0] = id
        }

        dispatch(ACTIONS.setCategoryFAQs(categoryFAQs));
    };

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} page="marketplace" title="Understand,control and grow your business" description="EVVA simplifies the financial life of SMEs, startups and freelancers."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
            }
            <MainSection title="Understand,control and grow your business." description="<h3>EVVA simplifies the financial life of SMEs, startups and freelancers.</h3>" position="left" image="hero-marketplace-en.png" actionLabel="Request your invitation" action={marketplaceFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpMarketplace">
                <AppBar pathname={appState.path} />
            </MainSection>
            <PageSection maxColumns="4" title="We know it is not easy to be an entrepreneur...">
              <Card
                  imageHeight="160px"
                  imageWidth="240px"
                  image="beaurocracy-icon.png"
                  description="<p>Bureaucracy, paperwork, long calls, visits to bank, and weeks of effort  to get a business bank account</p>"
                  textAlign="center"
                  descriptionColor="#363636"
              />
              <Card
                  imageHeight="160px"
                  imageWidth="250px"
                  image="insane-interest-rates-icon.png"
                  description="<p>Getting financing is time consuming,  small letters all around, insane interests rates  and lack of transparency</p>"
                  textAlign="center"
                  descriptionColor="#363636"
              />
              <Card
                  imageHeight="160px"
                  imageWidth="245px"
                  image="sat-tax-information-icon.png"
                  description="<p>SAT Tax Information? No thank you. It's difficult to handle, to understand, to process and manage</p>"
                  textAlign="center"
                  descriptionColor="#363636"
              />
              <Card
                  imageHeight="160px"
                  imageWidth="220px"
                  image="insecure-transaction-icon.png"
                  description="<p>Insecure transactions and fraud all around when trying to buy or sell. Scammers are just around the corners</p>"
                  textAlign="center"
                  descriptionColor="#363636"
              />
            </PageSection>
            <PageSection title="Introducing EVVA Marketplace" description="Entrepreneur, we got your back...Though our digital platform and partners, EVVA connects you to the best financial products, simplifying the daily financial needs of your business " maxWidth={contentMaxWidth}>
                <Card
                    title="EVVA bussines account"
                    description="<p>With EVVA you get a business bank account that makes your money grow, no more paperwork and bureaucracy</p>"
                    descriptionColor="#363636"
                    image="logo-bussines-account.svg"
                    imageWidth="200px"
                    imageHeight="200px"
                    imageBgColor="_evva_business"
                    link="/en/digital-business-account"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAAccount"
                    hasBgImage
                    imageShadow
                />
                <Card
                    title="SAT insights"
                    description="<p>Connect your SAT account, and EVVA give you a simple business cash flow in real time, no more darkness and uncertainty.</p>"
                    descriptionColor="#363636"
                    image="logo-sat-white.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_sat_info_en"
                    link="/en/taxes-sat"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVASAT"
                    hasBgImage
                    imageShadow
                />
                {/*<Card
                    title="EVVA Comunidad"
                    description="<p>Entrepreneur, Freelancer, Creator, connect with your community and generate income through direct contributions from your fans, audience and clients making happen what you really love to do.</p>"
                    descriptionColor="#363636"
                    image="logo-evva-community.svg"
                    imageWidth="200px"
                    imageHeight="200px"
                    imageBgColor="_evva_donation"
                    link="http://evva.co/"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickEvvaComunidad"
                    hasBgImage
                    imageShadow
                />*/}
            </PageSection>
            <PageSection maxColumns={2} maxWidth={contentMaxWidth}>
                <Card
                    subtitle="SME financing & loans"
                    description="<p>Expand, boost cash flow or fund your next step with finance that arrives in days with EVVA Uniclick financing</p>"
                    descriptionColor="#363636"
                    image="logo-uniclick-white.png"
                    imageWidth="240px"
                    imageHeight="200px"
                    imageBgColor="_uniclick"
                    link="/en/financing"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAUniclick"
                    imageShadow
                />
                <Card
                    subtitle="Secure transactions"
                    description="<p>Are you selling or buying? Avoid fraud and Secure your money with Fido Digital Escrow powered by EVVA</p>"
                    descriptionColor="#363636"
                    image="logo-fido.png"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_fido"
                    link="https://www.escrow.fido.mx/"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAFido"
                    imageShadow
                />
                <Card
                    subtitle="Hire freelancers"
                    description="<p>Hire remote professionals in one click from around the world per project. Receive $300 free on your first hire</p>"
                    descriptionColor="#363636"
                    image="logo-worq-lab.png"
                    imageWidth="250px"
                    imageHeight="200px"
                    imageBgColor="_worq_lab"
                    link="https://worqlab.com/"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAWorqLab"
                    imageShadow
                />
                <Card
                    subtitle="Digital Contracts"
                    description="<p>With Mifiel Create and sign digital contracts for your customer and partners using your e.Firma</p>"
                    descriptionColor="#363636"
                    image="logo-mifiel.png"
                    imageWidth="200px"
                    imageHeight="200px"
                    imageBgColor="_mifiel"
                    link="https://www.mifiel.com/es/"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAMifiel"
                    imageShadow
                />
                <Card
                    subtitle="Evva digital rewards"
                    description="<p>Create Digital Rewards and loyalty,  Increase your sales and retain your customers with EVVA wallet</p>"
                    descriptionColor="#363636"
                    image="logo-evva-wallet.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/en/wallet"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAWallet"
                    imageShadow
                />
                {/*
                 <Card
                    subtitle="EVVA Entrepreneurs"
                    description="<p>A Business account, Business education and Perks tailored made for young entrepreneurs</p>"
                    descriptionColor="#363636"
                    image="logo-evva-entrepreneurs.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/en"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAEntrepreneurs"
                    imageShadow
                />
                */}
            </PageSection>
            <PageSection maxWidth="980px" bgColor="#F9F9F9">
                <Card
                    title="Join our mission"
                    description="<p>Are you bank, fintech or startup and share our mission?</p>"
                    descriptionColor="#363636"
                    actionLabel="Get in touch"
                    action={contactUsFormHandler}
                    image="legal-guide.png"
                    imageWidth="420px"
                    imageHeight="340px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection title="Frequently asked questions">
                <FAQs items={dataFAQs} filters={dataFilters} categoryFAQsHandler={categoryFAQsHandler} categoryFAQs={appState.categoryFAQs}/>
            </PageSection>
            <SecondarySection
                title="Together we grow"
                shortDescription="From Entrepreneurs to entrepreneurs, we are people like you who understand your needs. Our ultimate goal is to let you focus on what really matters ... Your business."
                position="center"
                image="evva-together.jpg"
                actionLabel="Request your invitation"
                action={marketplaceFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpMarketplace"
            />
        </Layout>
    )
};

export default Marketplace
